import { createI18n } from 'vue-i18n';

import config from '@/config';
import nl from '@/locales/nl.json';

export default createI18n({
  locale: config.app.language,
  fallbackLocale: config.app.language,
  messages: { nl },
});
