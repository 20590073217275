import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12a0fb8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "not-found" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_list_item_section = _resolveComponent("va-list-item-section")!
  const _component_va_list_item = _resolveComponent("va-list-item")!

  return (_openBlock(), _createBlock(_component_va_list_item, null, {
    default: _withCtx(() => [
      _createVNode(_component_va_list_item_section, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.message), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}