import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50fe35e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row justify--space-between align--center mb-6" }
const _hoisted_2 = { class: "display-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Help = _resolveComponent("Help")!
  const _component_Form = _resolveComponent("Form")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createBlock(_component_va_modal, {
    ref: "modal",
    onBeforeOpen: _ctx.fetchQuery,
    stateful: true,
    "fixed-layout": true,
    "hide-default-actions": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        onDone: _ctx.handleDone,
        query: _ctx.query,
        modalForm: ""
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('search.edit-search-query')), 1),
            _createVNode(_component_Help)
          ])
        ]),
        _: 1
      }, 8, ["onDone", "query"])
    ]),
    _: 1
  }, 8, ["onBeforeOpen"]))
}