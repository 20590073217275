
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';

import {
  SECTION_TYPES,
  WORKSPACE_KINDS,
  WORKSPACE_TYPES,
} from '@/config/enums';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';
import AddCalendarDirectory from '@/modules/calendar/modals/AddDirectory.vue';
import AddNewsDirectory from '@/modules/news/modals/AddDirectory.vue';
import { Query } from '@/services/api/modules/queries';
import { Workspace } from '@/services/api/modules/workspaces';
import { State } from '@/store';

export default defineComponent({
  components: {
    AddNewsDirectory,
    AddCalendarDirectory,
  },

  props: {
    query: {
      type: Object as () => Query,
      required: false,
    },
    type: {
      type: String as () => SECTION_TYPES,
      required: true,
    },
    searchData: {
      type: Object,
      required: true,
    },
  },

  emits: ['done'],

  setup(props, { emit }) {
    const store = useStore<State>();

    const workspaces = computed(
      () => store.getters['workspaces/getItems'] as Workspace[],
    );

    const workspaceOptions = computed(() => {
      const types = [WORKSPACE_TYPES.PRIVATE];
      const kind =
        props.type === SECTION_TYPES.CALENDAR
          ? [WORKSPACE_KINDS.CALENDARS, WORKSPACE_KINDS.CALENDAR]
          : [WORKSPACE_KINDS.COLUMN];

      const features = store.state.auth.user?.features;
      if (features?.manageSharedWorkspaces) types.push(WORKSPACE_TYPES.SHARED);

      return workspaces.value
        ?.filter(
          (item) => kind.includes(item.wsKind) && types.includes(item.wsType),
        )
        .map((item) => ({ text: item.title, value: item.id }));
    });

    const modal = ref();
    const addModal = ref();
    const { message, setMessage, clearMessage } = useMessage();
    const form = ref();
    const loading = ref(false);
    const name = ref('');
    const workspace = ref(workspaceOptions.value?.[0]);

    const isSharedWorkspace = computed(
      () =>
        workspaces.value.find((item) => item.id === workspace.value.value)
          ?.wsType === WORKSPACE_TYPES.SHARED,
    );

    watch(workspaceOptions, (value, oldValue) => {
      const newOption = value.find(
        (item) => !oldValue.some((oldItem) => oldItem.value === item.value),
      );

      if (newOption) {
        workspace.value = newOption;
      } else {
        workspace.value = workspaceOptions.value?.[0];
      }
    });

    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const showAdd = () => {
      addModal.value?.show();
    };

    const handleSubmit = () => {
      loading.value = true;
      clearMessage();

      const dispatchName = props.query
        ? 'workspaces/updateSearchQuery'
        : 'workspaces/saveSearchQuery';

      store
        .dispatch(dispatchName, {
          id: workspace.value.value,
          queryId: props.query?.id,
          name: name.value || props.query?.name,
          title: name.value || props.query?.name,
          queryText: props.searchData.queryText,
          queryTitle: props.searchData.queryTitle,
          queryAuthor: props.searchData.queryAuthor,
          period: props.searchData.period.value,
          fromDate: props.searchData.fromDate,
          toDate: props.searchData.toDate,
          sources: props.searchData.sourcesIds,
          calendars: props.searchData.calendarsIds,
        })
        .then(setMessage('search.save-success'))
        .then(() => {
          emit('done');
          modal.value?.hide();

          name.value = '';
          workspace.value = workspaceOptions.value?.[0];
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      SECTION_TYPES,
      modal,
      addModal,
      showAdd,
      message,
      form,
      loading,
      name,
      workspace,
      workspaceOptions,
      isSharedWorkspace,
      hide,
      show,
      handleSubmit,
      ...useValidation(),
    };
  },
});
