import { kebabCase } from 'lodash';
import { Component } from 'vue';
import { LocationAsRelativeRaw } from 'vue-router';
import { Store } from 'vuex';

import SidebarSettings from '@/components/modals/SidebarSettings/Index.vue';
import AddCalendarDirectory from '@/modules/calendar/modals/AddDirectory.vue';
import AddNewsDirectory from '@/modules/news/modals/AddDirectory.vue';
import i18n from '@/plugins/i18n';
import { Workspace } from '@/services/api/modules/workspaces';
import { State } from '@/store';

import { SECTION_TYPES, WORKSPACE_KINDS } from '../enums';
import { CALENDAR, CLIPPINGS, NEWS, SEARCH } from '../routes';

export interface SubItem {
  id: string;
  title: string;
  render: boolean;
  to: LocationAsRelativeRaw;
  children?: SubItem[];
}

export interface Control {
  id: string;
  title: string;
  modal: Component;
  modalProps?: {
    [key: string]: string;
  };
}

export interface MainItem {
  id: string;
  title: string;
  icon: string;
  to: LocationAsRelativeRaw;
  children?: SubItem[];
  controls?: Control[];
}

const mapWorkspace = (routeName: string) => (workspace: Workspace) => {
  return {
    id: workspace.id,
    title: workspace.title,
    render: true,
    to: {
      name: routeName,
      params: { id: workspace.id },
    },
    children: workspace.items.map((item) => ({
      id: item.resourceId,
      title: item.title,
      render: item.settings?.isVisible ?? true,
      to: {
        name: routeName,
        params: {
          id: workspace.id,
          resourceId: item.resourceId,
        },
        hash: routeName === NEWS ? `#${kebabCase(item.title)}` : undefined,
      },
    })),
  };
};

function navigation(store: Store<State>): MainItem[] {
  const workspaces = store.getters['workspaces/getItems'] as Workspace[];
  const features = store.state.auth.user?.features;

  return [
    {
      id: NEWS,
      icon: 'article',
      title: i18n.global.t('common.news'),
      to: { name: NEWS },
      children: workspaces
        ?.filter((workspace) => workspace.wsKind === WORKSPACE_KINDS.COLUMN)
        .map(mapWorkspace(NEWS)),
      controls: [
        {
          id: 'manage-directories',
          title: i18n.global.t('news.manage-directories'),
          modal: SidebarSettings,
          modalProps: { type: SECTION_TYPES.NEWS },
        },
        {
          id: 'add-directory',
          title: i18n.global.t('news.add-directory'),
          modal: AddNewsDirectory,
        },
      ],
      render: store.getters['workspaces/newsEnabled'],
    },
    {
      id: CALENDAR,
      icon: 'event',
      title: i18n.global.t('common.calendar'),
      to: { name: CALENDAR },
      children: workspaces
        ?.filter(
          (workspace) =>
            workspace.wsKind === WORKSPACE_KINDS.CALENDARS ||
            workspace.wsKind === WORKSPACE_KINDS.CALENDAR,
        )
        .map(mapWorkspace(CALENDAR)),
      controls: [
        {
          id: 'manage-calendars',
          title: i18n.global.t('calendar.manage-directories'),
          modal: SidebarSettings,
          modalProps: { type: SECTION_TYPES.CALENDAR },
        },
        {
          id: 'add-directory',
          title: i18n.global.t('calendar.add-directory'),
          modal: AddCalendarDirectory,
        },
      ],
      render: store.getters['workspaces/calendarEnabled'],
    },
    {
      id: SEARCH,
      icon: 'search',
      title: i18n.global.t('common.search'),
      to: { name: SEARCH },
      render: features?.useSourceSearch,
    },
    {
      id: CLIPPINGS,
      icon: 'content_cut',
      title: i18n.global.t('common.clippings'),
      to: { name: CLIPPINGS },
      render: features?.useClippings,
    },
  ].filter((item) => item.render);
}

export default navigation;
