import { pick } from 'lodash';

import { AUTH_VERIFY } from '@/config/routes';
import router from '@/plugins/router';

import http from '../http';
import searchParams from '../searchParams';

export interface Cookie {
  cookieName: string;
  cookieValue: string;
  sessionDuration: number;
  timestamp?: number;
}

export interface Google {
  url: string;
}

export interface Credentials {
  email: string;
  password: string;
  remember?: boolean;
}

export interface ChangePassword {
  password: string;
}

export interface ForgotPassword {
  email: string;
}

export interface ResetPassword {
  code: string;
  email: string;
  password: string;
}

export interface UserFeatures {
  useSystemWorkspace: boolean;
  useSharedWorkspaces: boolean;
  manageOrganisation: boolean;
  manageSharedWorkspaces: boolean;
  managePrivateWorkspaces: boolean;
  manageSharedQueries: boolean;
  managePrivateQueries: boolean;
  useClippings: boolean;
  startWithAgenda: boolean;
  useSourceSearch: boolean;
  subscribeNewsAlert: boolean;
  readNewsletters: boolean;
  createNewsletter: boolean;
}

export interface User {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  mobilePhone: string;
  kind: string;
  features: UserFeatures;
}

export interface StoreUser {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
}

const login = (user?: Credentials): Promise<Cookie> => {
  if (!user) {
    return http.get('/login');
  }

  const token = btoa(`${user.email}:${user.password}`);

  return http.get('/login', {
    headers: {
      Authorization: `Basic ${token}`,
      RememberMe: user.remember ? '1' : '0',
    },
  });
};

const google = (): Promise<Google> => {
  const { href } = router?.resolve({ name: AUTH_VERIFY }) || {};

  const params = searchParams({
    returnUrl: window.location.origin + href,
  });

  return http.get(`/login/google${params}`);
};

const logout = (): Promise<undefined> => {
  return http.get('/logout');
};

const forgotPassword = (item: ForgotPassword): Promise<undefined> => {
  return http.get(`/users/${item.email}/forgotten`);
};

const resetPassword = (item: ResetPassword): Promise<undefined> => {
  return http.post(`/login/${item.code}`, {
    username: item.email,
    password: item.password,
  });
};

const changePassword = (item: ChangePassword): Promise<undefined> => {
  return http.put('/users/currentuser/pwd', item);
};

const user = (): Promise<User> => {
  return http.get('/users/currentuser');
};

const updateUser = (user: StoreUser): Promise<User> => {
  return http.put(
    '/users/currentuser',
    pick(user, ['firstName', 'lastName', 'gender', 'mobilePhone']),
  );
};

export default {
  login,
  google,
  logout,
  forgotPassword,
  resetPassword,
  changePassword,
  user,
  updateUser,
};
