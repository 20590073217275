import auth from './modules/auth';
import calendars from './modules/calendars';
import collections from './modules/collections';
import newsletters from './modules/newsletters';
import objects from './modules/objects';
import queries from './modules/queries';
import search from './modules/search';
import settings from './modules/settings';
import sources from './modules/sources';
import workspaces from './modules/workspaces';

export default {
  auth,
  calendars,
  collections,
  newsletters,
  objects,
  queries,
  search,
  settings,
  sources,
  workspaces,
};
