import { createLogger, createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth, { State as AuthState } from './modules/auth';
import calendars, { State as CalendarsState } from './modules/calendars';
import collections, { State as CollectionsState } from './modules/collections';
import global, { State as GlobalState } from './modules/global';
import newsletters, { State as NewslettersState } from './modules/newsletters';
import objects, { State as ObjectsState } from './modules/objects';
import settings, { State as SettingsState } from './modules/settings';
import sources, { State as SourcesState } from './modules/sources';
import workspaces, { State as WorkspacesState } from './modules/workspaces';

export interface State {
  auth: AuthState;
  calendars: CalendarsState;
  collections: CollectionsState;
  global: GlobalState;
  newsletters: NewslettersState;
  objects: ObjectsState;
  settings: SettingsState;
  sources: SourcesState;
  workspaces: WorkspacesState;
}

const plugins = [createPersistedState()];

if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger());
}

export default createStore<State>({
  modules: {
    auth,
    calendars,
    collections,
    global,
    newsletters,
    objects,
    settings,
    sources,
    workspaces,
  },
  plugins,
});
