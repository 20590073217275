export const AUTH_LOGIN = 'auth-login';
export const AUTH_FORGOT_PASSWORD = 'auth-forgot-password';
export const AUTH_RESET_PASSWORD = 'auth-reset-password';
export const AUTH_CREATE_ACCOUNT = 'auth-create-account';
export const AUTH_VERIFY = 'auth-verify';
export const NEWS = 'news';
export const NEWS_ARTICLE = 'news-article';
export const CALENDAR = 'calendar';
export const CALENDAR_ARTICLE = 'calendar-article';
export const SEARCH = 'search';
export const SEARCH_ARTICLE = 'search-article';
export const CLIPPINGS = 'clippings';
export const SETTINGS = 'settings';
export const PRIVACY_POLICY = 'privacy-policy';
