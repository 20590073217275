
import { defineComponent, ref } from '@vue/runtime-core';

import api from '@/services/api';
import { Query } from '@/services/api/modules/queries';
import { Resource } from '@/services/api/modules/workspaces';

import Form from '../components/Form.vue';
import Help from '../components/Help.vue';

export default defineComponent({
  props: {
    resource: {
      type: Object as () => Resource,
      required: true,
    },
  },

  components: {
    Form,
    Help,
  },

  emits: ['done'],

  setup(props, { emit }) {
    const modal = ref();
    const hide = () => modal.value?.hide();
    const show = () => modal.value?.show();

    const query = ref<Query>();
    const fetchQuery = async () => {
      query.value = await api.queries.show(props.resource.resourceId);
    };

    const handleDone = () => {
      hide();
      emit('done');
    };

    return {
      modal,
      hide,
      show,
      query,
      fetchQuery,
      handleDone,
    };
  },
});
