import { pick } from 'lodash';

import mapEvents from '@/services/mapping/mapEvents';

import http from '../http';
import listOptions, { ListOptions } from '../listOptions';
import {
  Article,
  ArticleList,
  ArticleMail,
  ArticleMedia,
  ArticleRelated,
} from './article.types';

export interface SearchQuery {
  id?: string;
  queryText?: string;
  queryTitle?: string;
  queryAuthor?: string;
  period?: string;
  fromDate?: string;
  toDate?: string;
  sources?: string[];
  calendars?: string[];
}

const news = (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);

  return http.post(
    `/items/searchresult${params}`,
    pick(item, [
      'queryText',
      'queryTitle',
      'queryAuthor',
      'period',
      'fromDate',
      'toDate',
      'sources',
    ]),
  );
};

const calendar = async (
  item: SearchQuery,
  options: ListOptions,
): Promise<ArticleList> => {
  const params = listOptions(options);

  const data = await http.post(
    `/calendars/items/searchresult${params}`,
    pick(item, ['queryText', 'period', 'fromDate', 'toDate', 'calendars']),
  );

  return {
    ...data,
    items: mapEvents(data.items),
  };
};

const article = (
  _: string,
  id: string,
  options?: ListOptions,
): Promise<Article> => {
  const params = listOptions(options);
  return http.get(`/items/searchresult/${id}${params}`);
};

const articleMedia = (_: string, id: string): Promise<ArticleMedia[]> => {
  return http.get(`/items/searchresult/${id}/media`);
};

const articleRelated = (
  _: string,
  id: string,
  options: ListOptions,
): Promise<ArticleRelated> => {
  const params = listOptions(options);
  return http.get(`/items/searchresult/${id}/related${params}`);
};

const articleMail = (item: ArticleMail): Promise<undefined> => {
  return http.post(`/items/searchresult/${item.id}/mail`, {
    username: item.email,
    subject: item.subject,
    comment: item.message,
  });
};

export default {
  news,
  calendar,
  article,
  articleMedia,
  articleRelated,
  articleMail,
};
